import React from 'react';
import { RedocStandalone } from 'redoc';
const swaggerJson = require('./json/swagger.json');

export default class App extends React.Component {

  render() {
    return (
      <RedocStandalone
        spec={swaggerJson}
        options={{
          nativeScrollbars: true,
          hideDownloadButton: true,
          hideHostname: true,
          theme: {
            colors: {
              primary: {
                main: '#dd5522'
              }
            }
          }
        }}
      />
    );
  }

}